import * as React from "react";
import styled from "styled-components";
import ContentWindow from "./ContentWindow";

import TabsHolder from "./TabsHolder";
import Toolbar from "./Toolbar";

type MainType = {
  prevPage?: string;
};

const Wrapper = styled.main`
  border-radius: 0 0 1rem 1rem;
  border-bottom: 3px solid #07689f;
  margin-bottom: 1rem;
`;

const Main: React.FC<MainType> = ({ children, prevPage = "" }) => {
  return (
    <Wrapper>
      <TabsHolder />
      <Toolbar prevPage={prevPage} hasPrev={Boolean(prevPage)} />
      <ContentWindow>{children}</ContentWindow>
    </Wrapper>
  );
};

export default Main;
