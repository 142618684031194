import * as React from "react";
import styled from "styled-components";
import { FaFacebook, FaInstagram, FaGithub } from "react-icons/fa";

const FooterWrapper = styled.footer`
  margin-top: auto;
  background: #07689f;
  padding: 1rem;
  color: #fafafa;

  .icon {
    color: #fafafa;
  }

  .content-wrapper {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .credits,
  .address {
    margin-bottom: 0.5rem;
  }

  .social-media a {
    margin-right: 0.5rem;
  }

  @media only screen and (min-width: 768px) {
    .content-wrapper {
      position: relative;
      flex-direction: row;
      justify-content: center;
      margin: 0;
      width: 100%;
      gap: 2rem;
    }

    .content-wrapper::before,
    .content-wrapper::after {
      content: "";
      position: absolute;
      background: #fafafa;
      width: calc(50% - 360px);
      height: 2px;
    }

    .content-wrapper::before {
      left: 0;
    }

    .content-wrapper::after {
      right: 0;
    }

    .credits,
    .address {
      margin-bottom: 0rem;
    }
  }
`;

const Footer: React.FC = () => {
  return (
    <FooterWrapper>
      <section className="content-wrapper">
        <div className="credits">
          &copy; {new Date().getFullYear()} I Dewa Gede Bisma Mahendra.
        </div>

        <div className="address">Batubulan, Gianyar, Bali.</div>

        <div className="social-media">
          Let's be friend :{" "}
          <a href="https://www.facebook.com/DwGdBisma/">
            <FaFacebook className="icon" />
          </a>
          <a href="https://www.instagram.com/dwbisma/">
            <FaInstagram className="icon" />
          </a>
          <a href="https://github.com/dewabisma">
            <FaGithub className="icon" />
          </a>
        </div>
      </section>
    </FooterWrapper>
  );
};

export default Footer;
