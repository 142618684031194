const getPathname = (pathname: string) => {
  const normalized = pathname.split("/");

  if (normalized.length > 1) {
    let newnormal = [normalized[0], normalized[1]];

    return newnormal.join("/");
  } else {
    return "/";
  }
};

export default getPathname;
