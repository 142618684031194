import * as React from "react";
import Styled from "styled-components";

import Main from "../../components/Main";
import Photo from "../../components/Photo";

import appleDev1 from "../../assets/images/devjourney/apple-dev-2.jpg";
import appleDev2 from "../../assets/images/devjourney/apple-dev-5.jpg";
import appleDev3 from "../../assets/images/devjourney/apple-dev-4.jpg";
import diklat from "../../assets/images/devjourney/diklat.jpg";
import Seo from "../../components/Seo";

const Wrapper = Styled.section`
    h2 {
      color: #1f1f1f;
      font-size: 2rem;
      margin: 0 0 1rem 0;
    }

    h3 {
      font-size: 1.5rem;
    }

    a {
      text-decoration: none;
    }

    img {
      width:100%
    }

    @media only screen and (min-width: 768px) {
      .content-photos {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        justify-content: center;

        div {
          width: 48%
        }
      }
    }
`;

const Devjourney: React.FC = () => {
  const { useLayoutEffect } = React;

  useLayoutEffect(() => {
    const element = document.getElementById("title");

    if (element) {
      element.scrollIntoView();
    }
  }, []);

  return (
    <Main prevPage="/">
      <Seo title="Devjourney | Activities" />

      <Wrapper>
        <h2 id="title">My Developing Journey</h2>

        <div className="content-body">
          <div className="content-text">
            <p>
              The first time I got to try programming was when I was in senior
              highschool. That time I was taught the pascal programming language
              by my teacher. Later, I decided to learn android development. It
              was fun but I couldn't put myself totally into it so stopped
              halfway.
            </p>

            <p>
              After a while, web development caught my interest. I didn't want
              to make the same half-hearted decision. So, I decided to learn web
              development seriously. My main sources for learning are{" "}
              <em>youtube</em> and <em>udemy</em>.{" "}
              <a
                href="https://www.youtube.com/user/TechGuyWeb"
                target="__blank"
                rel="noreferrer"
              >
                Traversy Media
              </a>{" "}
              is one of the reason I can be what I'm today. The channel has so
              many great contents that is shared for free. I have endless
              gratitude for Brad for creating that youtube channel. Beside
              youtube, I also learn from the courses in Udemy. It's a great
              place for finding online course with affordable price.
            </p>

            <p>
              Beside online courses, I have also participate in a short coding
              bootcamp that is held by{" "}
              <abbr title="Balai Diklat Indonesia">BDI</abbr> Denpasar. In the
              bootcamp, I learned some best practice of starting a project. The
              best thing for me is the new friends that I made there!
            </p>

            <p>
              Later on after working for a while in web development, one of my
              friend talked to me about Apple Developer Academy, an academy that
              is created by Apple. It offers full scholarship for learning about
              how to think and Apple ecosystem app development. To get the
              scholarship you have to pass a rigorous screening process that is
              provided by them. I feel so lucky to be able to join the academy
              and proudly graduate as my final team project was one of the
              honorable mention in the graduation day.
            </p>

            <p>
              Not only that, I also won the{" "}
              <abbr title="World Wide Developer Conference">WWDC</abbr> 2023
              Swift Student Challenge Award. The Apple Swift Student Challenge
              is a global competition for students to showcase their creativity
              and coding skills by creating an app playground using the Swift
              programming language.
            </p>
          </div>
        </div>

        <h3>Photos</h3>

        <div className="content-photos">
          <Photo
            img={diklat}
            imgAlt="Bootcamp"
            caption="Bootcamp Lab at BDI"
            loading="lazy"
          />

          <Photo
            img={appleDev2}
            imgAlt="WWDC 2023 Award"
            caption="WWDC 2023 Award"
            loading="lazy"
          />

          <Photo
            img={appleDev3}
            imgAlt="Apple Developer Academy Final Project Showcase"
            caption="Apple Developer Academy Final Project Showcase"
            loading="lazy"
          />

          <Photo
            img={appleDev1}
            imgAlt="Apple Developer Academy Final Project Team"
            caption="Apple Developer Academy Final Project Team"
            loading="lazy"
          />
        </div>
      </Wrapper>
    </Main>
  );
};

export default Devjourney;
