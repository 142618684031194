import * as React from "react";
import styled from "styled-components";

interface CardType {
  className?: string;
  title: string;
  img: string;
  imgAlt?: string;
}

const Wrapper = styled.div`
  margin-bottom: 1rem;

  .card-text {
    color: #fafafa;
    background: #07689f;
    border-radius: 1rem;
    /* border: 1px solid #07689f; */
    display: flex;
    justify-content: center;
  }

  .card-text h3 {
    margin: 0;
    padding: 0.5rem 0;
    font-size: 1.5rem;
  }

  .card-img {
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-top: 1rem; */
    /* background: #07689f; */
    border: 1px solid #07689f;
    border-radius: 1rem;
    width: 100%;
    filter: saturate(0.3);
    transition: filter 0.5s;
    &:hover {
      filter: saturate(1);
    }
  }

  .card-img img {
    border-radius: 2rem;
    padding: 1rem 1rem;
    width: 100%;
  }
`;

const CardItem: React.FC<CardType> = ({ title, img, imgAlt, className }) => {
  return (
    <Wrapper className={className}>
      <div className="card-text">
        <h3>{title}</h3>
      </div>

      <div className="card-img">
        <img src={img} alt={imgAlt} />
      </div>
    </Wrapper>
  );
};

export default CardItem;
