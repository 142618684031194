import * as React from "react";
import * as ReactDOM from "react-dom";
import "normalize.css";
import "./assets/main.scss";

import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
