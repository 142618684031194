import * as React from "react";
import styled from "styled-components";

const Wrapper = styled.main`
  width: 80%;
  min-height: 300px;
  padding: 1rem 0;
  margin: 0 auto;
`;

const ContentWindow: React.FC = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default ContentWindow;
