import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  FaInfo,
  FaTasks,
  FaPencilRuler,
  FaHammer,
  FaPaw,
} from "react-icons/fa";

import Tab from "./Tab";

import getPathname from "../utils/pathname";

const Wrapper = styled.div`
  margin-top: 1rem;
  border-radius: 1rem 1rem 0 0;
  height: 1.75rem;
  background: #07689f;
  display: flex;

  p {
    margin-left: 0.3rem;
    width: 0;
    color: #fafafa;
    font-size: 0.8rem;
  }

  .favicon {
    color: white;
  }

  .tab-link {
    height: 100%;
    width: 100%;
    border-right: 1px solid #fafafa;
    text-decoration: none;
    display: block;
  }

  .tab-link:last-child {
    border-right: none;
  }

  .first-tab {
    .tab-button {
      border-radius: 1rem 0rem 0 0;
    }
  }

  .last-tab {
    .tab-button {
      border-radius: 0rem 1rem 0 0;
    }
  }

  @media only screen and (min-width: 768px) {
    & {
      height: 2rem;
    }
  }
`;

const TabsHolder: React.FC = () => {
  const { pathname } = useLocation();
  const newPathname = getPathname(pathname);

  return (
    <Wrapper>
      <Link to="/" className="tab-link first-tab">
        <Tab active={newPathname === "/" || newPathname === "/activities"}>
          <FaTasks className="favicon" />
          <p>What Do I Do?</p>
        </Tab>
      </Link>

      <Link to="/about" className="tab-link">
        <Tab active={newPathname === "/about"}>
          <FaInfo className="favicon" />
          <p>About Me</p>
        </Tab>
      </Link>

      <Link to="/expertise" className="tab-link">
        <Tab active={newPathname === "/expertise"}>
          <FaPencilRuler className="favicon" />
          <p>My Expertise</p>
        </Tab>
      </Link>

      <Link to="/projects" className="tab-link">
        <Tab active={newPathname === "/projects"}>
          <FaHammer className="favicon" />
          <p>My Projects</p>
        </Tab>
      </Link>

      <Link to="/contact" className="tab-link last-tab">
        <Tab active={pathname === "/contact"}>
          <FaPaw className="favicon" />
          <p>Let's Be Friend</p>
        </Tab>
      </Link>
    </Wrapper>
  );
};

export default TabsHolder;
