import * as React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  FaArrowLeft,
  FaArrowRight,
  FaLock,
  FaStar,
  FaUserSecret,
  FaEllipsisV,
} from "react-icons/fa";
import { GrRefresh } from "react-icons/gr";

type ToolbarType = {
  hasPrev?: boolean;
  prevPage?: string;
};

const Wrapper = styled.div`
  height: 2.5rem;
  padding: 0.3rem 0;
  background: #4b9cca;
  display: flex;

  .page-navigation {
    width: 20%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: stretch;
    align-items: stretch;
  }

  .nav-button {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1f1f1f3d;
    border-radius: 3rem;
  }

  .nav-button.active-nav {
    color: #1f1f1f;
  }

  .nav-button.active-nav:hover {
    background: rgba(255, 255, 255, 0.151);
    transition: linear 500ms;
  }

  .address-bar {
    background: #fafafa;
    border-radius: 1rem;
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .user-setting {
    width: 20%;
    /* background: green; */
  }

  .icon {
    width: 15%;
  }

  .address-input {
    width: 70%;
    white-space: nowrap;
    overflow: hidden;
  }

  .user-setting {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (min-width: 768px) {
    .page-navigation {
      width: 10%;
      margin: 0 0.5rem;
    }

    .nav-button {
      font-size: 1.5rem;
    }

    .address-bar {
      width: 80%;
    }

    .user-setting {
      width: 10%;

      font-size: 1.5rem;
    }

    .icon {
      width: 7%;
    }

    .address-input {
      width: 86%;
    }
  }
`;

const Toolbar: React.FC<ToolbarType> = ({ prevPage, hasPrev }) => {
  const history = useHistory();
  const hasNext = false;

  const navigate = (url: string | undefined) => {
    if (url) {
      history.push(url);
    }
  };

  return (
    <Wrapper>
      <div className="page-navigation">
        <div
          className={`nav-button ${hasPrev ? "active-nav" : ""}`}
          onClick={() => navigate(prevPage)}
        >
          <FaArrowLeft />
        </div>

        <div className={`nav-button ${hasNext ? "active-nav" : ""}`}>
          <FaArrowRight />
        </div>

        <div className="nav-button active-nav">
          <GrRefresh onClick={() => history.push("/")} />
        </div>
      </div>

      <div className="address-bar">
        <FaLock className="icon" />
        <div className="address-input">{window.location.href}</div>
        <FaStar className="icon" />
      </div>

      <div className="user-setting">
        <FaUserSecret />
        <FaEllipsisV />
      </div>
    </Wrapper>
  );
};

export default Toolbar;
