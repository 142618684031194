import * as React from "react";
import Styled from "styled-components";

import Main from "../components/Main";
import Seo from "../components/Seo";

const Wrapper = Styled.section`
  color: #1f1f1f;
  

  .fieldset {
    border-radius: 0.25rem;
    border: 1px solid #4b9cca;
  }

  .form-input {
    margin-bottom: 1rem;
  }

  .form-input input {
    padding: 0.25rem 0.5rem;
  }

  .form-input textarea {
    resize:vertical;
  }

  .form-input input, .form-input textarea {
    border-radius: 0.25rem;
    border: 1px solid #4b9cca;
    width:100% !important;
  }

  .form-input input:focus-visible {
    outline-width: 1rem;
    outline-color: #FF7E67;
  }

  .form-input textarea:focus-visible {
    outline-width: 1rem;
    outline-color: #FF7E67;
  }

  #legenda {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  h2 {
      color: #1f1f1f;
      font-size: 2rem;
      margin: 0 0 1rem 0;
    }

  @media only screen and (min-width:768px) {
    display:flex;
    justify-content:center;
    gap:2rem;

    .contact-info {
      width: 40%;
    }

    form {
      width: 40%;
    }

  } 
`;

const Contact: React.FC = () => {
  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");

  const formHandler = (e: React.FormEvent) => {
    // handleSubmit(e);

    setName("");
    setEmail("");
    setPhone("");
    setMessage("");

    alert("Thanks for contacting me! I'll get back to you soon!");
  };

  return (
    <Main>
      <Seo title="Contact | Bisma Mahendra" />
      <Wrapper>
        <div className="contact-info">
          <h2>Let's Be Friends</h2>

          <p>
            If you think there is something great we can do together, don't be
            shy to contact me! I'd love to know more about you and your great
            idea!
          </p>
        </div>

        <form onSubmit={formHandler}>
          <fieldset className="fieldset">
            <legend id="legenda">Drop Me a Message!</legend>

            <div className="form-container">
              <div className="form-group">
                <label>Name</label>

                <div className="form-input">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    placeholder="wayan"
                  />
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>

                <div className="form-input">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    placeholder="wayan@something.com"
                  />
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="name">Phone Number</label>

                <div className="form-input">
                  <input
                    type="text"
                    id="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    name="phone"
                    placeholder="+6281234567890"
                  />
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="message">Message:</label>

                <div className="form-input">
                  <textarea
                    name="message"
                    id="message"
                    rows={10}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Hello!"
                    required
                  ></textarea>
                </div>
              </div>
            </div>

            <button className="button" type="submit">
              Send
            </button>
          </fieldset>
        </form>
      </Wrapper>
    </Main>
  );
};

export default Contact;
