import * as React from 'react';
import styled from 'styled-components';

interface TabButton {
  active?: boolean;
}

const Wrapper = styled.div`
  background: #07689f;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0 0.3rem;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background: #2b77a3;
  }

  &:active {
    background: #4b9cca;
  }

  &.active {
    background: #4b9cca;
  }
`;

const Tab: React.FC<TabButton> = ({ children, active }) => {
  return <Wrapper className={`tab-button ${active ? 'active' : ''}`}>{children}</Wrapper>;
};

export default Tab;
