import * as React from "react";
import Styled from "styled-components";
import Main from "../../components/Main";

import home from "../../assets/images/projects/msgstore/home.png";
import homepage from "../../assets/images/projects/msgstore/homepage.png";
import productpage from "../../assets/images/projects/msgstore/productpage.png";
import orderdetail from "../../assets/images/projects/msgstore/orderdetail.png";
import Seo from "../../components/Seo";

const Wrapper = Styled.section`
  color: #1f1f1f;
  max-width: 700px;
  margin: 0 auto;
  
  h2 {
      color: #1f1f1f;
      font-size: 2rem;
      margin: 0 0 1rem 0;
    }

    h4 {
      font-size: 1.5rem;
    }

    img {
        width: 100%;
    }
`;

const MSGStore: React.FC = () => {
  const { useLayoutEffect } = React;

  useLayoutEffect(() => {
    const element = document.querySelector("h2");

    if (element) {
      element.scrollIntoView();
    }
  }, []);

  return (
    <Main prevPage="/projects">
      <Seo title="MSG Store | Projects" />

      <Wrapper>
        <h2>MSG Store</h2>

        <p>
          This site frontend is made using React. It uses Redux as its state
          management. For managing data, it uses mongoDB which is a
          document-oriented database. Lastly, it uses node.js with express.js to
          handle the business logic.
        </p>

        <p>This site is designed for simple and easy shopping experience.</p>

        <p>You can check some of its features below.</p>

        <div className="project-page">
          <h4>Home Page</h4>

          <img src={home} alt="home MSG store" />
          <img src={homepage} alt="home MSG store" />

          <p>
            Homepage will show you the top three selling items and the latest
            products. You can click the product image to go to the product page.
            You can also check cart by clicking the icon on the top right corner
            beside the sign in icon.
          </p>
        </div>

        <div className="project-page">
          <h4>Product Page</h4>

          <img src={productpage} alt="product page MSG store" />

          <p>
            You will be able to find the details about the product such as
            product rating, quantity, price, and description. You can also
            comment or read the comment there. Lastly, you can add the product
            that you want to your cart by clicking the cart icon.
          </p>
        </div>

        <div className="project-page">
          <h4>Order Detail</h4>

          <img src={orderdetail} alt="order detail MSG store" />

          <p>
            This page will contain all the info about your order from the
            products that you have bought, shipping information, payment, and
            total price.
          </p>
        </div>

        <p>
          If you want to test it live, you can access it by clicking the button
          below
        </p>

        <a
          href="https://msg-store.herokuapp.com/"
          target="_blank"
          rel="noreferrer"
        >
          <button>Live Demo</button>
        </a>
      </Wrapper>
    </Main>
  );
};

export default MSGStore;
