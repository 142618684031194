import * as React from "react";
import { Link } from "react-router-dom";
import Styled from "styled-components";
import CardItem from "../components/CardItem";
import Main from "../components/Main";

import teach from "../assets/images/activities/teaching.jpg";
import volunteer from "../assets/images/activities/volunteering-small.jpg";
import webdev from "../assets/images/activities/webdev.jpg";
import Seo from "../components/Seo";

const Wrapper = Styled.section`
  h2 {
    color: #1f1f1f;
    font-size: 2rem;
    margin: 0 0 1rem 0;
  }

  a{
      text-decoration:none;
    }

    @media only screen and (min-width: 768px) {
      .activities-container {
        display: flex;
        justify-content: center;
        gap: 1rem;

        a {
          width: 30%;
        }
      }
    }
`;

const Home: React.FC = () => {
  return (
    <Main>
      <Seo title="What Do I Do? | Bisma Mahendra" />

      <Wrapper>
        <h2>What Do I Do?</h2>

        <div className="activities-container">
          <Link to="/activities/teaching">
            <CardItem title="Teaching" img={teach} imgAlt="Teaching" />
          </Link>

          <Link to="/activities/volunteer">
            <CardItem
              title="Volunteering"
              img={volunteer}
              imgAlt="Volunteering"
            />
          </Link>

          <Link to="/activities/devjourney">
            <CardItem
              title="Software Development"
              img={webdev}
              imgAlt="Software Development"
            />
          </Link>
        </div>
      </Wrapper>
    </Main>
  );
};

export default Home;
