import * as React from "react";
import { Helmet } from "react-helmet-async";

type Meta = {
  title?: string;
  description?: string;
};

const Seo: React.FC<Meta> = ({
  title = "Bisma Mahendra",
  description = "Bisma's portofolio site",
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
};

export default Seo;
