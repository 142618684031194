import * as React from "react";
import Styled from "styled-components";
import Main from "../components/Main";
import Project from "../components/Project";

import msgStore from "../assets/images/projects/msgstore/msgstore.jpg";
import jktrans from "../assets/images/projects/jktrans/jktrans.jpg";
import heartEshop from "../assets/images/projects/hearteshop/hearteshop.jpg";
import Seo from "../components/Seo";

const Wrapper = Styled.section`
  color: #1f1f1f;

  h2 {
    color: #1f1f1f;
    font-size: 2rem;
    margin: 0 0 1rem 0;
  }

  @media only screen and (min-width:768px) {
    .projects-container {
    display: flex;
    justify-content: space-between;

      .project-card {
        width: 30%
      }
    }
  }
`;

const Projects: React.FC = () => {
  return (
    <Main>
      <Seo title="Projects | Bisma Mahendra" />
      <Wrapper>
        <h2>My Past Project</h2>

        <div className="projects-container">
          <Project
            title="MSG Store"
            img={msgStore}
            imgAlt="MSG Store"
            description="MSG Store is an e-commerce website that is built using MERN stack. It also use redux as its state management."
          />
          <Project
            title="Jktrans Export"
            img={jktrans}
            imgAlt="jktrans"
            description="Jktrans Export is a web app that improves bookkeeping experience. It is built using MERN stack."
          />
          <Project
            title="Heart Eshop"
            img={heartEshop}
            imgAlt="hearteshop"
            description="Heart E-shop is an easy and cheap solution to buy online game cash. It is built using Next.js, Node.js, MongoDB, and RabbitMQ"
          />
        </div>
      </Wrapper>
    </Main>
  );
};

export default Projects;
