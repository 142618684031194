import * as React from "react";
import Styled from "styled-components";

import Main from "../../components/Main";
import Photo from "../../components/Photo";

import volunteering from "../../assets/images/volunteering/griyaluhu.jpg";
import volunteering1 from "../../assets/images/volunteering/griyaluhu2.jpg";
import volunteering2 from "../../assets/images/volunteering/griyaluhu4.jpg";
import volunteering3 from "../../assets/images/volunteering/griyaluhu6.jpg";
import Seo from "../../components/Seo";

const Wrapper = Styled.section`
  h2 {
    color: #1f1f1f;
    font-size: 2rem;
    margin: 0 0 1rem 0;
  }

  h3 {
      font-size: 1.5rem;
  }

  a {
    text-decoration: none;
  }

  img {
    width:100%
  }

  @media only screen and (min-width:768px) {
    .content-photos {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 1rem;

      div {
        width: 48%
      }
    }


  }
`;

const Volunteer: React.FC = () => {
  const { useLayoutEffect } = React;

  useLayoutEffect(() => {
    const element = document.getElementById("title");

    if (element) {
      element.scrollIntoView();
    }
  }, []);

  return (
    <Main prevPage="/">
      <Seo title="Volunteering | Activities" />

      <Wrapper>
        <h2 id="title">My Volunteering Life</h2>

        <div className="content-body">
          <div className="content-text">
            <p>
              Being a volunteer is one of the best decision I have made so far.
              I met so many awesome people regardless of their background. It
              broaden my point of view in life. I have done many times of
              volunteering in various activities. But most of the time is about
              environment issues.
            </p>

            <p>
              The best volunteering experience that I have so far is with{" "}
              <a
                href="https://www.instagram.com/griya_luhu/"
                target="__blank"
                rel="noreferrer"
              >
                Griya Luhu
              </a>
              .{" "}
              <a
                href="https://www.instagram.com/griya_luhu/"
                target="__blank"
                rel="noreferrer"
              >
                Griya Luhu
              </a>{" "}
              is an organization that is moving in handling environmental
              issues. So, what have we done up to this point? You can check out
              the pictures below for some of our activities or just head to our
              instagram at{" "}
              <a
                href="https://www.instagram.com/griya_luhu/"
                target="__blank"
                rel="noreferrer"
              >
                Griya Luhu
              </a>{" "}
              for details.
            </p>
          </div>
        </div>

        <h3>Photos</h3>

        <div className="content-photos">
          <Photo
            img={volunteering}
            imgAlt="donating food"
            caption="Donating food to a foundation in Pandemic Day"
          />

          <Photo
            img={volunteering1}
            imgAlt="donating bottles"
            caption="Donating drinking bottle and creating water refill point at an elementary school in Karangasem"
          />

          <Photo
            img={volunteering2}
            imgAlt="socialization"
            caption="Doing socialization about waste management and waste bank at SD Negeri 1 Pejeng"
          />

          <Photo
            img={volunteering3}
            imgAlt="Proposing"
            caption="Proposing school waste management system to several school representatives in Bali"
          />
        </div>
      </Wrapper>
    </Main>
  );
};

export default Volunteer;
