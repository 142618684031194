import * as React from "react";
import Styled from "styled-components";

import Main from "../components/Main";

import fotoBisma from "../assets/images/about/bisma-close-up.jpg";
import Seo from "../components/Seo";

const Wrapper = Styled.section`
  h2 {
    color: #1f1f1f;
    font-size: 2rem;
    margin: 0 0 1rem 0;
  }

  img {
    width: 100%;
    border-radius: 10px;
  }

  .about-me-wrapper {
    display: flex;
    flex-direction: column;
    gap:1rem;
    align-items: center;
  }

  @media only screen and (min-width:768px) {
    img.portrait {
      height: 400px;
      border-radius: 10px;
    }

    .about-me-wrapper {
      display: flex;
      gap:1rem;
      flex-direction: row;
      align-items: start;

      .about-me-text {
        p:first-child {
          margin-top:0;
        }
      }
    }
  }
`;

const About: React.FC = () => {
  return (
    <Main>
      <Seo title="About | Bisma Mahendra" />
      <Wrapper>
        <h2>About Me</h2>

        <div className="about-me-wrapper">
          <img className="portrait" src={fotoBisma} alt="Bisma's portrait" />

          <div className="about-me-text">
            <p>
              Hello! My full name is I Dewa Gede Bisma Mahendra. My friends
              usually call me Bisma. I'm a passionate youth from Bali Island
              that is well known for its tourism. I was into programming when I
              was in Senior Highschool. My teacher selected me as the
              representative for the computer olympiad. Fortunately, I was 1
              <sup>st</sup> place in the regency level.
            </p>

            <p>
              I teach myself programming and web development further. Aspiring
              to be an expert in this field requires me to learn a lot of
              things. After intense learning and practicing, I start working as
              a full-stack web developer. While working, I have learned a lot of
              experience that helps me grow even further as a software engineer.
              After working for a while as a full-time software engineer, I
              started to seek further study, I joined Apple Developer Academy at
              Infinite Learning Batam. There I was learning a lot about how to
              think and Apple ecosystem app development.
            </p>

            <p>
              Becoming a great developer is really though. There are so many
              things that need to be learned and practiced. Despite that, I
              really love it, especially the problem solving moment, it's the
              best part I think. Seeing what I develop can make people happy is
              something I take pride in as a developer.
            </p>
          </div>
        </div>
      </Wrapper>
    </Main>
  );
};

export default About;
