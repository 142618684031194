import * as React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import { HelmetProvider } from "react-helmet-async";

import Header from "./components/Header";
import Home from "./pages/Home";
import Teaching from "./pages/Activities/Teaching";
import Volunteer from "./pages/Activities/Volunteer";
import About from "./pages/About";
import Expertise from "./pages/Expertise";
import Projects from "./pages/Projects";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";
import MSGStore from "./pages/Projects/MSGStore";
import Jktrans from "./pages/Projects/Jktrans";
import Heart from "./pages/Projects/HeartEshop";
import Devjourney from "./pages/Activities/Devjourney";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <Container>
        <Header />

        <Router>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/activities/teaching" exact>
              <Teaching />
            </Route>
            <Route path="/activities/volunteer" exact>
              <Volunteer />
            </Route>
            <Route path="/activities/devjourney" exact>
              <Devjourney />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/expertise">
              <Expertise />
            </Route>
            <Route path="/projects" exact>
              <Projects />
            </Route>
            <Route path="/projects/msg-store" exact>
              <MSGStore />
            </Route>
            <Route path="/projects/jktrans-export" exact>
              <Jktrans />
            </Route>
            <Route path="/projects/heart-eshop" exact>
              <Heart />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
          </Switch>
        </Router>

        <Footer />
      </Container>
    </HelmetProvider>
  );
};

export default App;
