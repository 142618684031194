import * as React from "react";
import Styled from "styled-components";
import Main from "../components/Main";
import CardItem from "../components/CardItem";

import react from "../assets/images/expertise/react.png";
import nodejs from "../assets/images/expertise/nodejs.png";
import mongodb from "../assets/images/expertise/mongodb.png";
import swift from "../assets/images/expertise/swift.svg";
import swiftui from "../assets/images/expertise/swiftui.png";
import Seo from "../components/Seo";

const Wrapper = Styled.section`
 h2 {
    color: #1f1f1f;
    font-size: 2rem;
    margin: 0 0 1rem 0;
  }
  
  .expertise .card-img {
    min-height: 300px
    
  }
  

  @media only screen and (min-width: 768px) {
    .expertise-container {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      gap: 2rem;

      .expertise {
        width: 30%;

        .card-img {
          height: 88%;
        }
      }
    }
  }
`;

const Expertise: React.FC = () => {
  return (
    <Main>
      <Seo title="Expertise | Bisma Mahendra" />

      <Wrapper>
        <h2>My Expertise</h2>

        <div className="expertise-container">
          <CardItem
            className="expertise"
            title="React"
            img={react}
            imgAlt="react js"
          />
          <CardItem
            className="expertise"
            title="NodeJS"
            img={nodejs}
            imgAlt="node js"
          />
          <CardItem
            className="expertise"
            title="MongoDB"
            img={mongodb}
            imgAlt="mongodb"
          />
          <CardItem
            className="expertise"
            title="Swift"
            img={swift}
            imgAlt="swift"
          />
          <CardItem
            className="expertise"
            title="SwiftUI"
            img={swiftui}
            imgAlt="swiftui"
          />
        </div>
      </Wrapper>
    </Main>
  );
};

export default Expertise;
