import * as React from "react";
import Styled from "styled-components";

import Main from "../../components/Main";
import Photo from "../../components/Photo";

import teaching from "../../assets/images/teaching/rotaract.jpg";
import teaching1 from "../../assets/images/teaching/rotaract1.jpg";
import teaching2 from "../../assets/images/teaching/rotaract2.jpg";
import teaching4 from "../../assets/images/teaching/rotaract4.jpg";
import Seo from "../../components/Seo";

const Wrapper = Styled.section`
  h2 {
    color: #1f1f1f;
    font-size: 2rem;
    margin: 0 0 1rem 0;
  }

  a {
    text-decoration:none;
  }

  h3 {
      font-size: 1.5rem;
  }

  img {
    width:100%
  }

  @media only screen and (min-width: 768px) {
    .content-photos {
      display: flex;
      justify-content: center;

      flex-wrap: wrap;
      gap: 1rem;

      div {
        width: 48%;
      }
    }
  }
`;

const Teaching: React.FC = () => {
  const { useLayoutEffect } = React;

  useLayoutEffect(() => {
    const element = document.getElementById("title");

    if (element) {
      element.scrollIntoView();
    }
  }, []);

  return (
    <Main prevPage="/">
      <Seo title="Teaching | Activities" />

      <Wrapper>
        <h2 id="title">My Teaching Life</h2>

        <div className="content-body">
          <div className="content-text">
            <p>
              I have been teaching for more than 3 years now. I have taught
              varied range of age groups, from kindergarten to adult. I mainly
              teach English and basic programming but sometimes I do teach other
              subject like Math for elementary student.
            </p>

            <p>
              Teaching has brought a color to my life. When I can help my
              student to do what they think they can't do, it really makes me
              feel proud. My proudest achievements so far are being able to make
              a student of mine who thought he couldn't do arithmetic excel in
              it to the point his mother expressed her gratitude to me. I feel
              delighted seeing my student growth. Another one is a student of
              mine who struggled with programming in swift finally able to
              develop a stand alone app that she was proud showcasing to people.
            </p>

            <p>
              I also do volunteering for teaching. I have done it at some
              schools and institutions. I do volunteering with{" "}
              <a
                href="https://www.instagram.com/rac_baliubud/"
                target="__blank"
                rel="noreferrer"
              >
                Rotaract Bali Ubud
              </a>
              . Here are some of the pictures when my friends and I were
              volunteering.
            </p>
          </div>
        </div>

        <h3>Photos</h3>

        <div className="content-photos">
          <Photo
            img={teaching}
            imgAlt="volunteer being a teacher"
            caption="Celebrating Indonesia's Independence Day by volunteering to teach children in Singaraja"
            loading="lazy"
          />

          <Photo
            img={teaching1}
            imgAlt="volunteer being a teacher"
            caption="Celebrating Indonesia's Independence Day by volunteered to teach children in Singaraja"
            loading="lazy"
          />

          <Photo
            img={teaching4}
            imgAlt="volunteer being a teacher"
            caption="My friends and I when we volunteered at an elementary school as an English Teacher"
            loading="lazy"
          />

          <Photo
            img={teaching2}
            imgAlt="volunteer being a teacher"
            caption="My friends and I when we volunteered at an elementary school as an English Teacher"
            loading="lazy"
          />
        </div>
      </Wrapper>
    </Main>
  );
};

export default Teaching;
