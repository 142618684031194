import * as React from "react";
import styled from "styled-components";

interface PhotoType {
  img: string;
  imgAlt: string;
  caption: string;
  loading?: "lazy" | "eager" | undefined;
}

const Wrapper = styled.div`
  h4 {
    margin-top: 0.25rem;
  }
`;

const Photo: React.FC<PhotoType> = ({ img, imgAlt, caption, loading }) => {
  return (
    <Wrapper>
      <img loading={loading} src={img} alt={imgAlt} />

      <h4>{caption}</h4>
    </Wrapper>
  );
};

export default Photo;
