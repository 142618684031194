import * as React from "react";
import styled from "styled-components";
import Typed from "typed.js";

const Container = styled.header`
  background: #07689f;
  padding: 2rem 1rem;

  section.content-wrapper {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  h1,
  h4 {
    margin: 0;
    color: #fafafa;
  }

  h1 {
    font-size: 1.5rem;
  }

  h4 {
    margin-top: 0.5rem;
  }

  @media only screen and (min-width: 768px) {
    section.content-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 150px;
    }

    h1 {
      font-size: 3rem;
    }

    h4 {
      font-size: 2rem;
    }
  }
`;

const options = {
  strings: [
    "a Full-stack Developer",
    "a Teacher",
    "an Apple Developer Academy Graduate",
    "a WWDC 2023 Winner",
  ],
  smartBackspace: false,
  fadeOutDelay: 1000,
  loop: true,
  typeSpeed: 70,
};

const Header: React.FC = () => {
  const { useLayoutEffect } = React;

  useLayoutEffect(() => {
    const typed = document.getElementById("typed");

    if (typed) {
      new Typed("#typed", options);
    }
  }, []);

  return (
    <Container>
      <section className="content-wrapper">
        <h1>Hi! I'm Bisma Mahendra</h1>
        <h4>
          I'm <span id="typed"></span>
        </h4>
      </section>
    </Container>
  );
};

export default Header;
