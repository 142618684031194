import * as React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import slugify from "../utils/slugify";

interface ProjectType {
  img: string;
  title: string;
  imgAlt: string;
  description: string;
}

const Wrapper = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  background: #f8f1f1;
  border-radius: 2rem 0 2rem 0;

  a {
    color: #1f1f1f;
  }

  a:hover {
    color: #505050;
  }

  .project-img {
    width: 100%;
    border-radius: 2rem 0 2rem 0;
  }

  .project-details {
    padding: 1rem;
    color: #1f1f1f;
  }

  .project-details h3 {
    margin: 0;
  }
`;

const Project: React.FC<ProjectType> = ({
  img,
  imgAlt,
  title,
  description,
}) => {
  const slug = slugify(title);
  return (
    <Wrapper className="project-card">
      <img src={img} alt={imgAlt} className="project-img" />
      <div className="project-details">
        <h3 className="project-title">{title}</h3>
        <p className="project-description">{description}</p>
        <Link className="btn" to={`/projects/${slug}`}>
          Read More
        </Link>
      </div>
    </Wrapper>
  );
};

export default Project;
