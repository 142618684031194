import * as React from "react";
import Styled from "styled-components";
import Main from "../../components/Main";

import homepage from "../../assets/images/projects/hearteshop/homepage.png";
import orderpage from "../../assets/images/projects/hearteshop/orderpage.png";
import adminSaldo from "../../assets/images/projects/hearteshop/admin-saldo.png";
import adminVendor from "../../assets/images/projects/hearteshop/admin-vendor.png";
import adminProduct from "../../assets/images/projects/hearteshop/admin-product.png";
import Seo from "../../components/Seo";

const Wrapper = Styled.section`
  color: #1f1f1f;
  max-width: 700px;
  margin: 0 auto;
  
  h2 {
      color: #1f1f1f;
      font-size: 2rem;
      margin: 0 0 1rem 0;
    }

    h4 {
      font-size:1.5rem;
    }

    img {
        width:100%;
    }
`;

const Simply: React.FC = () => {
  const { useLayoutEffect } = React;

  useLayoutEffect(() => {
    const element = document.querySelector("h2");

    if (element) {
      element.scrollIntoView();
    }
  }, []);

  return (
    <Main prevPage="/projects">
      <Seo title="Heart E-shop | Projects" />

      <Wrapper>
        <h2>Heart E-Shop</h2>

        <p>
          Heart E-shop is an online store that uses automation and message queue
          for handling buying process that help seller handle many transactions
          easily.
        </p>

        <p>
          It's also shipped with admin page where seller can manage transaction,
          open time, product, and many more.
        </p>

        <div className="project-page">
          <h4>Home Page</h4>

          <img src={homepage} alt="heart e-shop home page" />

          <p>
            In this page, you can pick a game's cash that you want to buy. You
            can also just search the game if it's not visible in the top of the
            game list using the search bar.
          </p>
        </div>

        <div className="project-page">
          <h4>Order Page</h4>

          <img src={orderpage} alt="order page" />

          <p>
            You will be able to make order in this page, by checking your
            nickname, picking nominal, payment method, and confirming order.
          </p>
        </div>

        <div className="project-page">
          <h4>Admin Page Saldo Setting</h4>

          <img src={adminSaldo} alt="admin page saldo setting" />

          <p>
            This admin page handle the game's saldo remaining. If seller want to
            add more saldo or decrease saldo to preserve some for his needs then
            it can be done here. Saldo is tracked for making sure transaction
            can be carried out.
          </p>
        </div>

        <div className="project-page">
          <h4>Admin Page Vendor Setting</h4>

          <img src={adminVendor} alt="admin page vendor setting" />

          <p>
            All the open times are handled on this page. Seller can pick what
            time to open or even close it by setting time to 00:00 for both open
            and close time. Customer won't be able to pick any nominal if the
            vendor open duration is over.
          </p>
        </div>

        <div className="project-page">
          <h4>Admin Page Product Management</h4>

          <img src={adminProduct} alt="admin page product management" />

          <p>
            Seller can manage the game products here. He can create or edit
            product's price, nominal, etc. He can also combine product to make a
            new combined nominal product. Of course he can also delete it.
          </p>
        </div>

        <p>
          If you want to access it live, you can visit the site by clicking the
          button below
        </p>

        <a href="https://www.hearteshop.com/" target="_blank" rel="noreferrer">
          <button>Live Site</button>
        </a>
      </Wrapper>
    </Main>
  );
};

export default Simply;
