import * as React from "react";

import Styled from "styled-components";
import Main from "../../components/Main";

import dashboard from "../../assets/images/projects/jktrans/dashboard.png";
import buatnota from "../../assets/images/projects/jktrans/buatnota.png";
import listnota from "../../assets/images/projects/jktrans/listnota.png";
import detailnota from "../../assets/images/projects/jktrans/detailnota.png";
import Seo from "../../components/Seo";

const Wrapper = Styled.section`
    color: #1f1f1f;
    max-width: 700px;
    margin: 0 auto;

    h2 {
      color: #1f1f1f;
      font-size: 2rem;
      margin: 0 0 1rem 0;
    }

    h4 {
      font-size: 1.5rem;
    }

    img {
        width: 100%;
    }
`;

const Jktrans: React.FC = () => {
  const { useLayoutEffect } = React;

  useLayoutEffect(() => {
    const element = document.querySelector("h2");

    if (element) {
      element.scrollIntoView();
    }
  }, []);

  return (
    <Main prevPage="/projects">
      <Seo title="Jktrans | Projects" />

      <Wrapper>
        <h2>Jktrans Export</h2>

        <p>
          This web app is made to improve the experience when bookkeeping
          transaction to be easier, efficient, and transparent.
        </p>

        <p>
          The frontend is made using React. It uses Redux as its state
          management. For managing data, it uses mongoDB which is a
          document-oriented database. Lastly, it uses node.js with express.js to
          handle the business logic.
        </p>

        <p>You can check some of its features below.</p>

        <div className="project-page">
          <h4>Dashboard Page</h4>

          <img src={dashboard} alt="dashboard jktrans" />

          <p>
            Dashboard page will let you see the overall data of your
            transactions. You can see the total transactions and the last ten
            transactions.
          </p>
        </div>

        <div className="project-page">
          <h4>Create Invoice Page</h4>

          <img src={buatnota} alt="buat nota page" />

          <p>
            You can create a new invoice by filling the available fields and add
            item to the table by clicking the add item button.
          </p>
        </div>

        <div className="project-page">
          <h4>Invoice List Page</h4>

          <img src={listnota} alt="list nota page" />

          <p>
            The list will let you find, manage, or print the invoice easily. You
            can find a specific invoice by typing a keyword on the search field.
            You can also print the invoice by clicking the print icon on the
            actions column in the table.
          </p>
        </div>

        <div className="project-page">
          <h4>Invoice Detail Page</h4>

          <img src={detailnota} alt="detail nota page" />

          <p>
            You can check the detail of the invoice here. It contains all the
            data about the invoice. You can also edit it if you find some
            mistake in the invoice by clicking the edit icon on the top right
            corner.
          </p>
        </div>

        <p>
          If you want to test it live, you can access it by clicking the button
          below
        </p>

        <a
          href="https://jktrans.herokuapp.com/"
          target="_blank"
          rel="noreferrer"
        >
          <button>Live Demo</button>
        </a>
      </Wrapper>
    </Main>
  );
};

export default Jktrans;
